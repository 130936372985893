import React, { createContext, useContext, ReactNode, useState, useEffect, useCallback } from 'react';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  sendPasswordResetEmail,
  User as FirebaseUser,
} from 'firebase/auth';
import { auth } from './firebase';
import { useLazyQuery } from '@apollo/client';
import { GET_CHECKOUT_BY_AUTH } from './gql/auth';

interface ExtendedUser extends FirebaseUser {
  profile?: any;
}

interface AuthContextType {
  loginWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  logout: () => void;
  isLoggedIn: boolean;
  authLoading: boolean;
  currentUser: ExtendedUser | null;
  // Consider removing setCurrentUser if not necessary
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [authLoading, setAuthLoading] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<ExtendedUser | null>(null);
  const [getCheckoutByAuth] = useLazyQuery(GET_CHECKOUT_BY_AUTH);

  const logout = useCallback(async (): Promise<void> => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      setCurrentUser(null);
      localStorage.removeItem("accessToken");
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  }, []);

  // Inactivity Timer Logic
  useEffect(() => {
    let logoutTimer: NodeJS.Timeout | null = null;

    const resetLogoutTimer = () => {
      if (logoutTimer) clearTimeout(logoutTimer);
      logoutTimer = setTimeout(async () => {
        console.log('User inactive for 4 hours. Logging out...');
        await logout();
      }, 4 * 60 * 60 * 1000); // 4 hours in milliseconds
    };

    const activityListener = () => {
      resetLogoutTimer();
    };

    // Attach event listeners for activity
    window.addEventListener('mousemove', activityListener);
    window.addEventListener('keydown', activityListener);
    window.addEventListener('scroll', activityListener);

    resetLogoutTimer(); // Initialize timer on mount

    return () => {
      if (logoutTimer) clearTimeout(logoutTimer);
      window.removeEventListener('mousemove', activityListener);
      window.removeEventListener('keydown', activityListener);
      window.removeEventListener('scroll', activityListener);
    };
  }, [logout]);

  useEffect(() => {
    // Set persistence
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        console.log('Persistence set to local');
      })
      .catch((error: any) => {
        console.error('Error setting persistence:', error);
      });

    const unsubscribe = onAuthStateChanged(auth, async (user: FirebaseUser | null) => {
      if (user?.emailVerified) {
       // console.log("user----", user);
        const token = await user.getIdToken();
        localStorage.setItem("accessToken", token);

        try {
          const { data } = await getCheckoutByAuth();
          if (data) {
            const profile = data.getVendorByAuth;
            const extendedUser: ExtendedUser = {
              ...user,
              profile,
            };
            setIsLoggedIn(true);
            setAuthLoading(false);
            setCurrentUser(extendedUser);
          } else {
            setIsLoggedIn(false);
            setAuthLoading(false);
            setCurrentUser(null);
            await logout();  // Firebase logout
          }
        } catch (error) {
          console.error('Error fetching checkout profile:', error);
          setIsLoggedIn(false);
          setAuthLoading(false);
          setCurrentUser(null);
          await logout(); // Ensure logout is called if there's an error
        }
      } else {
        setIsLoggedIn(false);
        setAuthLoading(false);
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const loginWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
    try {
      const userCredentials = await signInWithEmailAndPassword(auth, email, password);
      if (!userCredentials?.user?.emailVerified) {
        const error = new Error("Email is not verified");
        await logout();
        throw error;
      }
    } catch (error) {
      console.error('Error logging in with email and password:', error);
      throw error;
    }
  };

  const resetPassword = async (email: string): Promise<void> => {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };

  const authContextValue: AuthContextType = {
    loginWithEmailAndPassword,
    resetPassword,
    logout,
    isLoggedIn,
    authLoading,
    currentUser,
    // Omit setCurrentUser if you decide to remove it
  };

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
