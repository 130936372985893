import React, { useEffect, useState, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ORDER_BY_ID } from "../../gql/order";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Divider,
  Grid,
  Avatar,
  Button,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import StoreIcon from "@mui/icons-material/Store";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import useStyles from "./useStyles";
import { useAuth } from "../../authprovider";
import { ErrorDisplay } from "../../components/ErrorDisplay";

export const Receipt: React.FC = () => {
  const classes = useStyles();
  const { orderId } = useParams<{ orderId: string }>();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [errorMessage, setErrorMessage] = useState("");
  const [authorized, setAuthorized] = useState(false); // New state to manage authorization
  const [getOrderById, { data, loading: queryLoading, error }] = useLazyQuery(
    GET_ORDER_BY_ID,
    { variables: { getOrderByIdId: orderId } }
  );

  const fetchOrder = useCallback(() => {
    getOrderById();
  }, [getOrderById]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  useEffect(() => {
    if (error) {
      setErrorMessage(`Error fetching order: ${error.message}`);
    }
  }, [error]);

  useEffect(() => {
    if (!queryLoading && data && currentUser) {
      const order = data?.getOrderById;

      if (!order) {
        setErrorMessage("No order data found.");
        return;
      }

      const isAuthorized = currentUser?.profile?.stores?.some(
        (store: any) => store._id === order.storeId
      );

      if (!isAuthorized) {
        setErrorMessage("You're not authorized to access this resource.");
      }

      setAuthorized(isAuthorized);
    }
  }, [data, currentUser, queryLoading]);

  if (queryLoading) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    );
  }

  if (errorMessage) {
    return <ErrorDisplay message={errorMessage} />;
  }

  if (!authorized) {
    // Show nothing if the user isn't authorized
    return null;
  }

  const order = data?.getOrderById;
  if (!order) return null;

  const { store, orderHistory, createdAt, createdByUser, transactionId } =
    order;

  const calculateTotals = () => {
    return orderHistory.items.reduce(
      (totals: any, item: any) => {
        totals.totalQuantity += item.quantity;
        totals.totalCost += item.quantity * item.price;
        totals.totalTax += item.quantity * item.gstin * item.price / 100;
        totals.carryBag += item.isCarryBag ? item.quantity : 0;
        totals.items += item.isCarryBag ? 0 : item.quantity;
        return totals;
      },
      { totalQuantity: 0, totalCost: 0, totalTax: 0, carryBag: 0, items: 0 }
    );
  };

  const { totalQuantity, totalCost, totalTax, carryBag, items } = calculateTotals();
  const totalPrice = totalCost + totalTax;
  const formattedDate = new Date(Number(createdAt)).toLocaleString();

  const handleScanNew = () => navigate("/home");

  return (
    <Box className={classes.container}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {/* Left Grid */}
        <Grid item xs={12} md={6} className={classes.leftGrid}>
          <Box className={classes.thankYouContainer}>
            {/* <img
              src={require("../../images/checkout.png")}
              alt="Thank You"
              className={classes.thankYouImage}
            />
            <Typography variant="h5" className={classes.thankYouNote}>
              Thank you for shopping with us! We appreciate your trust and hope
              you had a seamless experience.
            </Typography> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent>
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      gap={3}
                    >
                      <img
                        src={require("../../images/bag-qty.png")}
                        alt="Bags"
                      />
                      <Box>
                        <Typography variant="h6" fontWeight={'bold'}>
                          {carryBag}
                        </Typography>
                        <Typography variant="body2" color="grey">Bags</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent>
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      gap={3}
                    >
                      <img
                        src={require("../../images/cloth.png")}
                        alt="Items"
                      />
                      <Box>
                        <Typography variant="h6" fontWeight={'bold'}>
                          {items}
                        </Typography>
                        <Typography variant="body2" color="grey">Items</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent>
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      gap={3}
                    >
                      <img
                        src={require("../../images/rupee.png")}
                        alt="Amount"
                      />
                      <Box>
                        <Typography variant="h6" fontWeight={'bold'}>
                          {totalPrice}
                        </Typography>
                        <Typography variant="body2" color="grey">Amount</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent>
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      gap={3}
                    >
                      <img
                        src={require("../../images/calendar.png")}
                        alt="Date"
                      />
                      <Box>
                        <Typography variant="h6" fontWeight={'bold'}>
                          {formattedDate}
                        </Typography>
                        <Typography variant="body2" color="grey">Date</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Box className={classes.desktopButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleScanNew}
              className={classes.scanNewButton}
            >
              Scan New
            </Button>
            </Box>
          </Box>
        </Grid>

        {/* Right Grid */}
        <Grid item xs={12} md={6} className={classes.rightGrid}>
          <Card className={classes.card}>
            <CardContent>
              {/* Header */}
                <Box className={classes.header}>
                <Typography variant="h5" className={classes.receiptTitle}>
                  Order Receipt
                </Typography>
                <Avatar 
                  src={store.vendor.logo} 
                  className={classes.logo} 
                  sx={{
                  borderRadius: '0px',
                  '& .MuiAvatar-img': {
                    objectFit: 'contain'
                  }
                  }} 
                />
                </Box>
              <Divider className={classes.divider} />

              {/* Store Info */}
              <Section
                title="Store Information"
                icon={<StoreIcon className={classes.icon} />}
              >
                <Typography>{store.address}</Typography>
                <Typography>GSTIN: {store.vendor.gstin}</Typography>
                <Typography>Contact: {store.phoneNo}</Typography>
              </Section>
              <Divider className={classes.divider} />

              {/* Bill Details */}
              <Section
                title="Bill Details"
                icon={<ReceiptLongIcon className={classes.icon} />}
              >
                <Typography>Date: {formattedDate}</Typography>
                <Typography>
                  Customer Name:{" "}
                  {`${createdByUser?.firstName} ${createdByUser?.lastName}`}
                </Typography>
                <Typography>Bill Number: {order._id}</Typography>
                <Typography>Payment ID: {transactionId}</Typography>
              </Section>
              <Divider className={classes.divider} />

              {/* Items Purchased */}
              <Section
                title="Items Purchased"
                icon={<LocalMallIcon className={classes.icon} />}
              >
                <Box className={classes.itemsContainer}>
                  <div className={classes.itemHeader}>
                    <span>Item</span>
                    <span className={classes.qtyColumn}>Qty</span>
                    <span className={classes.amountColumn}>Price</span>
                    <span className={classes.amountColumn}>Tax</span>
                    <span className={classes.amountColumn}>Total</span>
                  </div>
                  {orderHistory.items.map((item: any, index: any) => (
                    <div key={index} className={classes.itemRow}>
                      <span className={classes.itemColumn}>{item.name}</span>
                      <span className={classes.qtyColumn}>{item.quantity}</span>
                      <span className={classes.amountColumn}>₹{item.price.toFixed(2)}</span>
                      <span className={classes.amountColumn}>
                        ₹{((item.price * item.gstin) / 100).toFixed(2)}
                      </span>
                      <span className={classes.amountColumn}>
                        ₹{((item.price + (item.price * item.gstin) / 100) * item.quantity).toFixed(2)}
                      </span>
                    </div>
                  ))}
                </Box>
              </Section>
              <Divider className={classes.divider} />

              {/* Summary */}
              <Summary
                totalQuantity={totalQuantity}
                totalCost={totalCost}
                totalTax={totalTax}
                totalPrice={totalPrice}
              />
              <Divider className={classes.divider} />

              <Box className={classes.mobileButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleScanNew}
                  className={classes.scanNewButton}
                >
                  Scan New
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

const Section: React.FC<{
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}> = ({ title, icon, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.section}>
      <Typography variant="h6">
        {icon}
        {title}
      </Typography>
      <Box className={classes.sectionContent}>{children}</Box>
    </Box>
  );
};

const Summary: React.FC<{
  totalQuantity: number;
  totalCost: number;
  totalTax: number;
  totalPrice: number;
}> = ({ totalQuantity, totalCost, totalTax, totalPrice }) => (
  <Box>
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Typography>Total Items:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography align="right">{totalQuantity}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Total Amount:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography align="right">₹{totalCost.toFixed(2)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Total Tax:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography align="right">₹{totalTax.toFixed(2)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">Grand Total:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography align="right" variant="h6">
          ₹{totalPrice.toFixed(2)}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);
