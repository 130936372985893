import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCbfYtHVX3vwaGtDiPTWE4NuYwN_TzXEL8",
  authDomain: "shop-pay-go.firebaseapp.com",
  projectId: "shop-pay-go",
  storageBucket: "shop-pay-go.appspot.com",
  messagingSenderId: "444426199120",
  appId: "1:444426199120:web:85864568d72e393dd77155"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };