import { gql } from "@apollo/client";

export const GET_ORDER_BY_ID = gql`
query Query($getOrderByIdId: ID!) {
  getOrderById(id: $getOrderByIdId) {
    _id
    userId
    storeId
    transactionId
    status
    createdBy
    createdAt
    updatedAt
    orderHistory {
      items {
        quantity
        price
        name
        gstin
        isCarryBag
      }
    }
    store {
      name
      phoneNo
      address
      vendor {
        logo
        email
        gstin
      }
    }
    createdByUser {
      firstName
      lastName
      phoneNo
    }
  }
}`;
