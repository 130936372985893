import { gql } from '@apollo/client';

export const GET_CHECKOUT_BY_AUTH = gql`
query GetCheckoutByAuth {
  getVendorByAuth {
    _id
    name
    address
    logo
    phoneNo
    email
    gstin
    upi
    bankDetails {
      accountNumber
      ifsc
      bankName
    }
    inboundApi
    outboundApi
    apiToken
    noOfStores
    role
    createdBy
    createdDate
    stores {
      _id
    }
  }
}
`;