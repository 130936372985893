import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { Home } from './pages/Home';
import { Navbar } from './components/Navbar';
import { Login } from './pages/Login/internal';
import { ProtectedRoute } from './ProtectedRoute';
import { PageNotFound } from './pages/pageNotFound';
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient';
import { Receipt } from './pages/Reciept';
import { PwaPrompt } from "react-ios-pwa-prompt-ts";

const theme = createTheme({
  palette: {
    primary: {
      main: '#281839',
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Router>
          <CssBaseline />
          <PwaPrompt promptOnVisit={1} copyTitle="Install Amaara Seetu App" timesToShow={1} copyClosePrompt="Close" permanentlyHideOnDismiss={true}/>
          <Navbar />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/pagenotfound" element={<PageNotFound />} />
            <Route path="/home" element={<ProtectedRoute />}>
              <Route path="/home" element={<Home />} />
            </Route>
            <Route path="/receipt/:orderId" element={<Receipt />} />
            <Route path="*" element={<Navigate to="/pagenotfound" />} />
          </Routes>
        </Router>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;