import React from 'react';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    fontFamily: 'Roboto, sans-serif',
  },
  card: {
    maxWidth: 400,
    textAlign: 'center',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    backgroundColor: '#fff',
  },
  icon: {
    fontSize: '50px',
    color: '#f44336',
  },
  button: {
    marginTop: '20px',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
  },
});

interface ErrorDisplayProps {
  message: string;
}

export const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ message }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/home');
  };

  return (
    <Box className={classes.container}>
      <Card className={classes.card}>
        <CardContent>
          <ErrorOutlineIcon className={classes.icon} />
          <Typography variant="h5" gutterBottom>
            Error
          </Typography>
          <Typography variant="body1" gutterBottom>
            {message}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleHome} className={classes.button}>
            Go to Home
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};