import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: '#f9f9f9',
  },
  leftGrid: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
  rightGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 12,
    boxShadow: theme.shadows[4],
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: 60,
    height: 60,
    
  },
  receiptTitle: {
    fontWeight: 600,
  },
  section: {
    marginBottom: 'theme.spacing(2)',
    '& h6': {
      fontSize: '1.1rem',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
  sectionContent: {
    fontSize: '0.9rem',
    color: '#666',
    '& p': {
      marginBottom: theme.spacing(0.5),
    },
  },
  itemsContainer: {
    width: '100%',
  },
  itemHeader: {
    display: 'grid',
    gridTemplateColumns: '2fr 0.5fr 1fr 1fr 1fr',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 0),
    borderBottom: '1px solid #eee',
    fontSize: '0.8rem',
    color: '#666',
    fontWeight: 500,
  },
  itemRow: {
    display: 'grid',
    gridTemplateColumns: '2fr 0.5fr 1fr 1fr 1fr',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 0),
    borderBottom: '1px solid #f5f5f5',
    fontSize: '0.85rem',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  itemColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  amountColumn: {
    textAlign: 'right',
  },
  qtyColumn: {
    textAlign: 'center',
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  detailsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  summary: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  thankYouContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'flex-start',
  },
  thankYouImage: {
    width: '80%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  thankYouNote: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: '20px !important',
  },
  scanNewButton: {
    marginTop: '16px !important',
    width: '100%',
    textTransform: 'none',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
  mobileButtonContainer: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
      width: '100%'
    },
  },
  desktopButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none', 
    },
  },
}));

export default useStyles;