import { FC } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Button,
  Stack,
  Divider,
  Grid,
} from "@mui/material";
import { dialogActionsStyles, dialogCancelActionStyles, dialogContentStyles, dialogHeaderStyles } from "../../styles/dialog";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import BadgeIcon from '@mui/icons-material/Badge';

interface AccountInfoDialogProps {
  open: boolean;
  onClose: () => void;
  userInfo: {
    name: string;
    role: string;
    email: string;
    phone?: string;
    address?: string;
  };
}

export const AccountInfoDialog: FC<AccountInfoDialogProps> = ({
  open,
  onClose,
  userInfo,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 20,
          background: "#ECE5F4",
          width: "500px",
          maxWidth: "90%",
        },
      }}
    >
      <DialogTitle sx={dialogHeaderStyles}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <span>Account Information</span>
          <AccountCircleIcon sx={{fontSize:"32px"}} />
        </Stack>
      </DialogTitle>
      <Divider sx={{ borderBottomWidth: '2px', color: 'primary.main' }} />
      <Box>
        <DialogContent sx={dialogContentStyles}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <BadgeIcon />
                <Typography component="span" fontSize={16} fontWeight={700}>
                  Name: {userInfo.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <BadgeIcon />
                <Typography component="span" fontSize={16} fontWeight={700}>
                  Role: {userInfo.role}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <EmailIcon />
                <Typography component="span" fontSize={16} fontWeight={700}>
                  Email: {userInfo.email}
                </Typography>
              </Stack>
            </Grid>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <PhoneIcon />
                  <Typography component="span" fontSize={16} fontWeight={700}>
                    Phone: {userInfo.phone}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <HomeIcon />
                  <Typography component="span" fontSize={16} fontWeight={700}>
                    Address: {userInfo.address}
                  </Typography>
                </Stack>
              </Grid>
          </Grid>
          <DialogActions sx={dialogActionsStyles}>
            <Button onClick={onClose} size="medium" sx={dialogCancelActionStyles}>
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Box>
    </Dialog>
  );
};