import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import { ForgotPasswordDialog } from '../../components/forgot-password-dialog/internal';
import { useAuth } from '../../authprovider';
import { CustomSnackbar } from '../../components/custom-snackbar';

const useInput = (
  initialValue: string,
  validator?: (value: string) => boolean
) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    setError(validator ? value !== '' && !validator(value) : false);
  };

  return { value, onChange, error };
};

const validateEmail = (email: string) =>
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email.toLowerCase());

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const username = useInput('', validateEmail);
  const password = useInput('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  const { loginWithEmailAndPassword, currentUser, resetPassword } = useAuth();
  useEffect(() => {
    if (currentUser) {
      navigate('/home');
    }
  }, [currentUser, navigate]);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      padding: '10px',
      height: '50px',
    },
    '& .MuiInputBase-root': { background: 'white' },
    mt: 0,
    minWidth: '100%',
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (
      !username.error &&
      !password.error &&
      username.value &&
      password.value
    ) {
      try {
        await loginWithEmailAndPassword(username.value, password.value);
        setSnackbarMessage('Login successful');
        setSnackbarSeverity('success');
        navigate('/home');
      } catch (error) {
        console.error('Login failed:', error);
        setSnackbarMessage('Login failed. Please check your credentials.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setSnackbarMessage('Invalid input');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Invalid input');
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleForgotPasswordOpen = () => {
    setForgotPasswordOpen(true);
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordOpen(false);
  };

  const handleForgotPasswordSend = async (email: string) => {
    try {
      await resetPassword(email);
    } catch (error: any) {
      console.error(error);
      throw new Error('Error sending password reset email');
    }
  };

  return (
    <Grid
      container
      sx={{
        height: { xs: 'calc(100vh - 56px)', md: 'calc(100vh - 64px)' },
        padding: 1,
        background: "linear-gradient(135deg, #ffffff 0%, #e0f7fa 100%)",
      }}
    >
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
        }}
      >
        <Box sx={{ mt: 1, width: '80%' }}>
          <form onSubmit={handleSubmit}>
            <Typography
              sx={{
                textAlign: { xs: 'center', sm: 'left' },
                fontSize: '32px',
                fontWeight: 'bold',
              }}
            >
              Welcome Back
            </Typography>
            <Typography
              sx={{
                textAlign: { xs: 'center', sm: 'left' },
                fontSize: '16px',
                mb: 6,
              }}
            >
              Please sign in to manage ShopPayGo
            </Typography>
            <Typography variant="subtitle2" fontWeight="bolder">
              Email
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              name="username"
              autoComplete="username"
              autoFocus
              {...username}
              error={username.error}
              helperText={
                username.error ? 'Please enter a valid email address.' : ''
              }
              sx={{
                ...commonTextFieldStyles,
                '& .MuiInputBase-input:-webkit-autofill': {
                  transition: 'background-color 5000s ease-in-out 0s',
                },
              }}
            />
            <Typography
              variant="subtitle2"
              fontWeight="bolder"
              align="left"
              mt={0}
            >
              Password
            </Typography>
            <Box display="flex" flexDirection="column" margin="normal">
              <Box display="flex" alignItems="center">
                <TextField
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  {...password}
                  error={password.error}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                          sx={{ mr: 1 }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ ...commonTextFieldStyles, flexGrow: 1 }}
                />
              </Box>
              {password.error && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ mt: '5px', ml: 2 }}
                >
                  Password isn't secure
                </Typography>
              )}
            </Box>
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography
                textAlign={'right'}
                mt={1}
                color={'#281839'}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={handleForgotPasswordOpen}
                fontSize={12}
                fontWeight={'bold'}
              >
                Forgot password?
              </Typography>
            </Box> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 2,
                height: '50px',
                borderRadius: '10px',
                textTransform: 'none',
                fontSize: '16px',
              }}
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                'Log In'
              )}
            </Button>
          </form>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          display: { xs: 'none', sm: 'block' },
          width: '100%',
          height: '100%',
          backgroundImage: `url(${require('../../images/login.png')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '20px',
        }}
      />
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
      {/* <ForgotPasswordDialog
        open={forgotPasswordOpen}
        onClose={handleForgotPasswordClose}
        onSend={handleForgotPasswordSend}
      /> */}
    </Grid>
  );
};
