import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { AccountCircle, ExitToApp } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../authprovider';
import { ConfirmDialog } from '../confirm-dialog';
import { AccountInfoDialog } from '../account-info';

export const Navbar: React.FC = () => {
  const { logout, currentUser } = useAuth();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAccountInfoDialog, setOpenAccountInfoDialog] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const hideIcons = location.pathname === '/';

  const handleConfirmLogout = () => {
    logout();
    //navigate('/');
    window.location.href = '/'; //need to reload the page to reset server context
    setOpenConfirmDialog(false);
  };

  const handleOpenAccountInfo = () => {
    setOpenAccountInfoDialog(true);
  };

  const userInfo = {
    name: currentUser?.profile?.name || '',
    //role: currentUser?.profile?.role || '',
    role: 'Guard',
    email: currentUser?.email || '',
    phone: currentUser?.profile?.phoneNo || '',
    address: currentUser?.profile?.address || '',
  };

  return (
    <AppBar position="static" >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{
          flexGrow: 1,
          fontSize: '20px',
          color: 'white',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}>
          ShopPayGo
        </Typography>
        {!hideIcons && (
          <Box>
            <IconButton edge="end" color="inherit" aria-label="account" sx={{ mr: 2 }} onClick={handleOpenAccountInfo}>
              <AccountCircle />
            </IconButton>
            <IconButton edge="end" color="inherit" aria-label="logout" onClick={() => setOpenConfirmDialog(true)}>
              <ExitToApp />
            </IconButton>
          </Box>
        )}
        <ConfirmDialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={handleConfirmLogout}
          message="Are you sure you want to logout?"
          title="Logout"
          icon={<ExitToApp sx={{ fontSize: "32px" }} />}
        />
        <AccountInfoDialog
          open={openAccountInfoDialog}
          onClose={() => setOpenAccountInfoDialog(false)}
          userInfo={userInfo}
        />
      </Toolbar>
    </AppBar>
  );
};