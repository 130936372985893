import React, { useState } from 'react';
import QrReader from 'react-qr-scanner';
import { Box, Typography, Theme, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CameraFront, CameraRear } from '@mui/icons-material';
import LogoImage from '../../images/logo.png';
import { useNavigate } from 'react-router-dom';

export const Home: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');

  const handleScan = (data: any) => {
    if (data) {
      const orderId = data.text.replace("Order ID: ", "").trim();
      navigate(`/receipt/${orderId}`);
    }
  };

  const handleError = (error: any) => {
    console.error('QR code scan error:', error);
  };

  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.qrScannerContainer}>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%', height: '100%' }}
          className={classes.qrReader}
          constraints={{
            video: { facingMode: facingMode }
          }}
        />
        <Box className={classes.overlay}>
          <Typography
            sx={{
              fontSize: '16px',
              position: 'relative',
              top: '-30%',
              color: '#fff',
            }}
          >
            Scan QR code to print receipt
          </Typography>
          <div className={`${classes.cornerSquare} ${classes.topLeft}`}></div>
          <div className={`${classes.cornerSquare} ${classes.topRight}`}></div>
          <div className={`${classes.cornerSquare} ${classes.bottomLeft}`}></div>
          <div className={`${classes.cornerSquare} ${classes.bottomRight}`}></div>
          <div className={classes.line}></div>
        </Box>
        <IconButton
          className={classes.toggleButton}
          onClick={toggleCamera}
        >
          {facingMode === 'user' ? <CameraRear /> : <CameraFront />}
        </IconButton>
      </Box>

      <Box className={classes.infoContainer}>
        <Typography sx={{ fontSize: '15px' }}>
          Position your phone to make sure the QR is within the frame
        </Typography>
        <img src={LogoImage} alt="Logo" className={classes.logo} />
        <Typography sx={{ fontSize: '14px' }}>
          Thank you for shopping with us!
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  qrScannerContainer: {
    position: 'relative',
    width: '100%',
    height: '60vh',
    overflow: 'hidden',
    background: 'transparent',
  },
  qrReader: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  overlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '50%',
    zIndex: 1,
    borderRadius: '10px',
    boxShadow: `
      0 0 0 100vw rgba(0, 0, 0, 50%), /* Outer square with background color */
      0 0 0 8px rgba(255, 255, 255, 0) /* Inner square that is hollow */
    `,
    [theme.breakpoints.up('sm')]: {
      '@media (orientation: landscape)': {
        height: '55%',
        left: '55%',
        top: '50%',
      },
    },
  },
  line: {
    position: 'absolute',
    width: '100%',
    height: '2px',
    background: '#fff',
    animation: '$moveUpDown  4s linear infinite',
    borderRadius: '50px',
  },
  '@keyframes moveUpDown': {
    '0%': { top: '0' },
    '50%': { top: '100%' },
    '100%': { top: '0' },
  },
  cornerSquare: {
    position: 'absolute',
    width: '8vh',
    height: '8vh',
    border: '6px solid #281839',
    zIndex: 0,
    borderColor: 'white',
  },
  topLeft: {
    top: '-15px',
    left: '-15px',
    borderRight: 0,
    borderBottom: 0,
    borderTopLeftRadius: '20px',
  },
  topRight: {
    top: '-15px',
    right: '-15px',
    borderBottom: 0,
    borderLeft: 0,
    borderTopRightRadius: '20px',
  },
  bottomLeft: {
    bottom: '-15px',
    left: '-15px',
    borderTop: 0,
    borderRight: 0,
    borderBottomLeftRadius: '20px',
  },
  bottomRight: {
    bottom: '-15px',
    right: '-15px',
    borderLeft: 0,
    borderTop: 0,
    borderBottomRightRadius: '20px',
  },
  infoContainer: {
    width: '100%',
    height: '30vh',
    backgroundColor: '#ECE5F4',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '30px',
    boxSizing: 'border-box',
    position: 'fixed',
    bottom: 0,
    zIndex: 100,
    borderTopLeftRadius: '40px',
    borderTopRightRadius: '40px',
  },
  logo: {
    width: '80px',
    height: '80px',
    marginTop: '20px',
  },
  thankYouText: {
    fontSize: '14px',
    color: '#000',
  },
  toggleButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#fff',
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      bottom: 50,
      right: 20,  
    },
  },
  // Media query for tab rotation
  '@media (orientation: landscape)': {
    root: {
      flexDirection: 'row-reverse',
      height: '91vh',
    },
    qrScannerContainer: {
      width: '55%',
      height: '100%',
    },
    infoContainer: {
      width: '50%',
      height: '92vh',
      padding: '16px',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      gap: '20px',
      left: 0,
      borderBottomRightRadius: '40px',
      borderTopLeftRadius: '0px',
    },
  },
}));