import React from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'warning' | 'info';
  onClose: () => void;
}

export const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, message, severity, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      sx={{
        '& .MuiSnackbarContent-root': {
          backgroundColor: severity === 'success' ? 'green' :
                           severity === 'error' ? 'red' :
                           severity === 'warning' ? 'orange' : 'blue',
          color: '#fff',
        },
      }}
    >
      <SnackbarContent message={message} />
    </Snackbar>
  );
};